<template>
    <div>
        <v-app-bar id="home-app-bar" app color="white" elevation="2" height="50" absolute hide-on-scroll>
            <router-link class="d-flex justify-start" :to="{ name: 'Home' }">
                <v-img :src="require('@/assets/img/logo.png')" contain max-width="100" width="100%" />
            </router-link>
            <div class="app-name indigo darken-4 text-center" :style="{ backgroundColor: contract.color + ' !important' }">
                <v-select item-text="name" :return-object="true" :items="$store.getters['contract/getContracts']" v-model="contract"></v-select>
                <!--<p class="white--text home-title font-weight-thin">Unterstützungsangebote <br>für Cisco-Rahmenverträge</p>-->
            </div>
            <v-tabs align-with-title>
                <v-tab to="/">Home</v-tab>
                <v-tab to="/about">About</v-tab>
            </v-tabs>
            <v-spacer />

            <UserInfo />
        </v-app-bar>
    </div>
</template>

<script>

import UserInfo from './UserInfo.vue'

export default {
    // This component describes the main navigation bar, or app-bar component, shown above every content page.

    name: "AppBar",
    components: {UserInfo },
    data() {
        return {
            items: [
                'Rahmenvertrag Voice',
                'Rahmenvertrag Netzwerke',
                'Rahmenvertrag 21000',
            ]
        }
    },
    computed: {
        contract: {
            get: function() {
                let contract = this.$store.getters["contract/getContract"]
                if(contract == null)
                    return { name: 'Laden...' }
                return contract
            },
            set: function(contract) {
                this.$store.commit("contract/setContract", contract)
            }
        }
    }

};
</script>

<style lang="sass">
#home-app-bar
    .v-tabs-slider
        max-width: 24px
        margin: 0 auto

    &:before
        display: none

.pointer
    cursor: pointer

.app-name
    padding: 0px 12px
    margin-left: 12px
    width: 250px
    white-space: nowrap
    height: 50px
    line-height: 50px
    margin-right: -25px
    div
        font-size: 14px
    * *
        text-overflow: clip
        color: white !important
        border: 1px solid transparent !important
    .v-input__slot:before, .v-input__slot:after
        display: none
    .v-select__slot 
        height: 45px
    i 
        margin-top: 10px
        color: white !important
.home-title
    height: 10px
    font-size: 12px
    font-weight: normal
    letter-spacing: 0.1875rem !important
    line-height: 18px
    padding-top: 7px
    font-family: "Poppins", "Helvetica" !important

.app-name > div > div > div.v-input__slot > div.v-select__slot > div.v-input__append-inner > div > i
    color: white !important
</style>
