<template>
  <div class="text-center user-info">
    <span class="mr-2">Hi, {{ firstname }}</span>
    <v-menu :close-on-content-click="false" v-model="menuVisible" :nudge-width="200" offset-x>
      <template v-slot:activator="{ on }">
        <v-avatar color="indigo" class="pointer" size="42" v-on="on" data-cy="userinfo-panel">
          <span class="white--text font-weight-bold">{{ avatar }}</span>
        </v-avatar>
      </template>

      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-avatar color="indigo" size="42">
              <span class="white--text font-weight-bold">{{ avatar }}</span>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ firstname }} {{ lastname }}</v-list-item-title>
              <v-list-item-subtitle>
                <v-btn text color="primary" small outlined v-if="$store.getters['user/getAdminGroup']"
                  @click="navigate('Admin')">Admin-Panel</v-btn>
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn color="error" small @click="
                signOut();
              menuVisible = false;
              " data-cy="signout">Logout</v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>
        <v-list :key="index" class="pa-0" v-for="(group, index) in $store.state.userInfo.groups">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ group }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { Auth } from "@aws-amplify/auth";
export default {
  // This component describes the main navigation user info component, shown above every content page.

  name: "UserInfo",
  data() {
    return {
      menuVisible: false
    }
  },
  computed: {
    firstname: function () {
      return this.$store.state.userInfo.firstname;
    },
    lastname: function () {
      return this.$store.state.userInfo.lastname;
    },
    fullname: function () {
      return this.$store.state.userInfo.fullname;
    },
    avatar: function () {
      return this.$store.state.userInfo.avatar;
    },
  },
  methods: {
    async signOut() {
      await Auth.signOut();
      this.$store.dispatch("setLoadState", false);
      location.reload();
    },
    navigate(routeName) {
      this.$router.push({ name: routeName });
      this.menuVisible = false
    }
  },
};
</script>
<style scoped lang="scss">
#home-app-bar {
  .v-tabs-slider {
    max-width: 24px;
    margin: 0 auto;
  }

  &:before {
    display: none;
  }
}

.pointer {
  cursor: pointer;
}

.user-info {
  width: 250px;
  margin-right: -15px;
}

.v-menu__content {
  z-index: 12 !important;
}
</style>
