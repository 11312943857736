var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        {
          attrs: {
            id: "home-app-bar",
            app: "",
            color: "white",
            elevation: "2",
            height: "50",
            absolute: "",
            "hide-on-scroll": "",
          },
        },
        [
          _c(
            "router-link",
            {
              staticClass: "d-flex justify-start",
              attrs: { to: { name: "Home" } },
            },
            [
              _c("v-img", {
                attrs: {
                  src: require("@/assets/img/logo.png"),
                  contain: "",
                  "max-width": "100",
                  width: "100%",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "app-name indigo darken-4 text-center",
              style: { backgroundColor: _vm.contract.color + " !important" },
            },
            [
              _c("v-select", {
                attrs: {
                  "item-text": "name",
                  "return-object": true,
                  items: _vm.$store.getters["contract/getContracts"],
                },
                model: {
                  value: _vm.contract,
                  callback: function ($$v) {
                    _vm.contract = $$v
                  },
                  expression: "contract",
                },
              }),
            ],
            1
          ),
          _c(
            "v-tabs",
            { attrs: { "align-with-title": "" } },
            [
              _c("v-tab", { attrs: { to: "/" } }, [_vm._v("Home")]),
              _c("v-tab", { attrs: { to: "/about" } }, [_vm._v("About")]),
            ],
            1
          ),
          _c("v-spacer"),
          _c("UserInfo"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }