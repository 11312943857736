var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-center user-info" },
    [
      _c("span", { staticClass: "mr-2" }, [
        _vm._v("Hi, " + _vm._s(_vm.firstname)),
      ]),
      _c(
        "v-menu",
        {
          attrs: {
            "close-on-content-click": false,
            "nudge-width": 200,
            "offset-x": "",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function (ref) {
                var on = ref.on
                return [
                  _c(
                    "v-avatar",
                    _vm._g(
                      {
                        staticClass: "pointer",
                        attrs: {
                          color: "indigo",
                          size: "42",
                          "data-cy": "userinfo-panel",
                        },
                      },
                      on
                    ),
                    [
                      _c(
                        "span",
                        { staticClass: "white--text font-weight-bold" },
                        [_vm._v(_vm._s(_vm.avatar))]
                      ),
                    ]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.menuVisible,
            callback: function ($$v) {
              _vm.menuVisible = $$v
            },
            expression: "menuVisible",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-list",
                [
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-avatar",
                        { attrs: { color: "indigo", size: "42" } },
                        [
                          _c(
                            "span",
                            { staticClass: "white--text font-weight-bold" },
                            [_vm._v(_vm._s(_vm.avatar))]
                          ),
                        ]
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              _vm._s(_vm.firstname) + " " + _vm._s(_vm.lastname)
                            ),
                          ]),
                          _c(
                            "v-list-item-subtitle",
                            [
                              _vm.$store.getters["user/getAdminGroup"]
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        text: "",
                                        color: "primary",
                                        small: "",
                                        outlined: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.navigate("Admin")
                                        },
                                      },
                                    },
                                    [_vm._v("Admin-Panel")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-action",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "error",
                                small: "",
                                "data-cy": "signout",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.signOut()
                                  _vm.menuVisible = false
                                },
                              },
                            },
                            [_vm._v("Logout")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _vm._l(_vm.$store.state.userInfo.groups, function (group, index) {
                return _c(
                  "v-list",
                  { key: index, staticClass: "pa-0" },
                  [
                    _c(
                      "v-list-item",
                      [
                        _c(
                          "v-list-item-content",
                          [_c("v-list-item-title", [_vm._v(_vm._s(group))])],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("v-divider"),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }